import SEO from '../components/SEO';
import SiteLayout from '../layouts/SiteLayout';

const BookMe = () => (
  <SiteLayout>
    <SEO title="Contact" />
    <h1>How can I help you?</h1>
    <p>
      If you wish to get in contact, send me an e-mail:{' '}
      <a href="mailto:hello@constantsolutions.dk">hello@constantsolutions.dk</a> or message me on{' '}
      <a href="https://www.linkedin.com/in/mattiasfjellvang/">LinkedIn</a>.
    </p>
  </SiteLayout>
);

export default BookMe;
